import React from 'react';
import SearchInput from './SearchInput';

const SearchContainer = React.lazy(() => import('./SearchContainer'));

const LazySearch = ({
  className = '',
  onResultClick,
}: {
  className?: string;
  onResultClick: () => void;
}) => {
  return (
    <React.Suspense fallback={<SearchInput onChange={() => {}} onFocus={() => {}} />}>
      <SearchContainer
        className={className}
        indices={[
          {
            name: process.env.GATSBY_ALGOLIA_INDEX_NAME || 'offbeat',
            title: 'Articles',
          },
        ]}
        onResultClick={onResultClick}
      />
    </React.Suspense>
  );
};

export default LazySearch;
