import classNames from 'classnames';
import React, { memo, useCallback, useContext, useRef, useState } from 'react';

import useClickOutside from '../hooks/useClickOutside';
import trackEvent from '../lib/trackEvent';
import { NewsletterOpenerContext } from '../providers/NewsletterProvider';
import LazySearch from './LazySearch';
import Menubar from './menubar';
import MobileMenuBar from './mobile-menubar';
import Newsletter from './newsletter';
import TabTrap from './tab-trap';

const Nav = ({ activeMenuItem }) => {
  const menuRef = useRef(null);
  const [isMainNavOpen, setMainNavOpen] = useState(false);
  const setNewsletterOpen = useContext(NewsletterOpenerContext);
  useClickOutside(
    menuRef,
    useCallback(() => setMainNavOpen(false), []),
  );

  const onMoreClick = () => {
    if (window.scrollY < 64) {
      window.scrollTo({ top: 64, behavior: 'smooth' });
    }
    setMainNavOpen(!isMainNavOpen);
    trackEvent('Mobile Nav Menu', isMainNavOpen ? 'Open' : 'Close');
  };

  const onClose = () => {
    if (isMainNavOpen) {
      setMainNavOpen(false);
      trackEvent('Mobile Nav Menu', 'Close');
    }
  };

  const onMenuLinkClick = () => {
    if (isMainNavOpen) {
      setMainNavOpen(false);
      trackEvent('Mobile Nav Menu', 'Close');
    }
  };

  return (
    <nav className="main-nav__container" ref={menuRef}>
      <MobileMenuBar
        isMainNavOpen={isMainNavOpen}
        onMenuItemClick={onMenuLinkClick}
        onMoreClick={onMoreClick}
        activeMenuItem={activeMenuItem}
      />
      <div
        className={classNames('main-nav js-main-nav', isMainNavOpen && 'open')}
        id="nav-menu"
        aria-hidden={!isMainNavOpen}
      >
        <LazySearch className="grow" onResultClick={onMenuLinkClick} />
        <div className="main-nav__newsletter">
          <Newsletter onClick={setNewsletterOpen} />
        </div>
        <Menubar onClick={onMenuLinkClick} activeMenuItem={activeMenuItem} />
        {isMainNavOpen && <TabTrap nextId="mobile-menu-more-trigger" />}
      </div>
    </nav>
  );
};

export default memo(Nav);
