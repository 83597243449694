import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import MenuContext from '../contexts/MenuContext';

import Link from './link';

const isSSR = typeof window === 'undefined';

function MenuBar({ onClick, activeMenuItem = '' }) {
  return (
    <ul role="menubar" className="main-nav__list flex items-center">
      {useContext(MenuContext).map(({ slug, title, menuTitle, shortTitle }) => (
        <li
          key={slug}
          role="menuitem"
          className={classNames('main-nav__item', 'main-nav__item--isLink', {
            active: !isSSR && RegExp(`^/${slug}/`).test(activeMenuItem),
          })}
        >
          <Link id={`nav-${slug}`} to={`/${slug}/`} onClick={onClick}>
            {menuTitle || shortTitle || title}
          </Link>
        </li>
      ))}
      <a
        href="https://www.instagram.com/offbeatbudapest/"
        target="_blank"
        rel="noopener noreferrer"
        className="main-nav__social"
        title="Instagram profile"
      >
        <svg role="presentation" aria-label="Instagram profile">
          <title>Instagram profile</title>
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-instagram" />
        </svg>
      </a>
      <a
        href="https://www.facebook.com/offthebeatbudapest/"
        target="_blank"
        rel="noopener noreferrer"
        className="main-nav__social"
        title="Facebook page"
      >
        <svg role="presentation" aria-label="Facebook page">
          <title>Facebook page</title>
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-facebook" />
        </svg>
      </a>
    </ul>
  );
}

MenuBar.defaultProps = {
  onClick: () => {},
};

MenuBar.propTypes = {
  onClick: PropTypes.func,
};

export default MenuBar;
