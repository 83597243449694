import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import search from '../images/icon-search3.svg';

const SearchInput = ({
  onChange,
  onFocus,
}: {
  onChange: (query: string) => void;
  onFocus: () => void;
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  return (
    <form
      role="search"
      className="search main-nav__search"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label htmlFor="searchtext" className="visuallyhidden">
        Start typing to search
      </label>
      <input
        ref={searchRef}
        name="query"
        id="searchtext"
        className="search__input js-search-field"
        type="text"
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'ArrowDown') {
            onFocus();
          }
        }}
        onFocus={onFocus}
      />
      <button
        className="imagebutton search__submit"
        type="button"
        aria-label="Click to search"
        onClick={(e) => {
          e.preventDefault();
          searchRef.current && searchRef.current.focus();
        }}
      >
        <img className="search__icon" src={search} alt="Submit search" />
      </button>
      <button
        className={classNames('imagebutton search__clear mr-3 lg:mr-0')}
        type="reset"
        aria-label="Clear search"
        onClick={() => {
          onChange('');
          searchRef.current && searchRef.current.focus();
        }}
      >
        <svg role="presentation" className="search__icon">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-close" />
        </svg>
      </button>
    </form>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

export default SearchInput;
