import classNames from 'classnames';
import { bool, func } from 'prop-types';
import React, { useContext } from 'react';

import MenuContext from '../contexts/MenuContext';
import Link from './link';

import { button, buttonNoDecoration } from '../scss/modules/button.module.scss';
import * as mobilenav from '../scss/modules/mobile-nav.module.scss';

function MobileMenuBar({ isMainNavOpen, onMenuItemClick, onMoreClick, activeMenuItem = '' }) {
  const menu = useContext(MenuContext).slice(0, 3);

  return (
    <div className={`${mobilenav.mobileNav} js-scroll-header`}>
      <ul role="menubar" className={mobilenav.mobileNav__menu}>
        {menu.map(({ slug, title, menuTitle, shortTitle }) => (
          <li
            key={slug}
            role="menuitem"
            className={classNames(mobilenav.mobileNav__item, {
              [mobilenav.active]: RegExp(`^/${slug}/`).test(activeMenuItem),
            })}
          >
            <Link to={`/${slug}/`} onClick={onMenuItemClick}>
              {menuTitle || shortTitle || title}
            </Link>
          </li>
        ))}
        <li className={mobilenav.mobileNav__item}>
          <button
            id="mobile-menu-more-trigger"
            onClick={onMoreClick}
            className={`${button} ${buttonNoDecoration} ${mobilenav.mobileNav__more}`}
            type="button"
            aria-controls="nav-menu"
            aria-expanded={isMainNavOpen}
          >
            More
          </button>
        </li>
      </ul>
    </div>
  );
}

MobileMenuBar.defaultProps = {
  isMainNavOpen: false,
};

MobileMenuBar.propTypes = {
  onMenuItemClick: func.isRequired,
  onMoreClick: func.isRequired,
  isMainNavOpen: bool,
};

export default MobileMenuBar;
